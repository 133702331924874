import { Modal } from 'antd';
import { Error } from '../error/Error';

type Props = {
  open: boolean;
  close: () => void;
  title?: string;
  description?: string;
  loading?: boolean;
  onSubmit?: () => void;
  textError?: string;
};

export const ConfirmationModal = ({ open, close, title, description, loading, onSubmit, textError }: Props) => {
  return (
    <Modal title={title} open={open} onOk={onSubmit} confirmLoading={loading} onCancel={close}>
      <p>{description}</p>
      {textError && <Error textError={textError} />}
    </Modal>
  );
};
