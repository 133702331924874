import { AxiosRequestConfig } from 'axios';
import { PageQueryParams } from '../models';
import fetchClient from './axiosInstance';

interface CategoryQueryParams extends PageQueryParams {
  category?: string | number;
  all?: boolean;
}

export default class CategoriesService {
  static async getCategories(params?: CategoryQueryParams, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/category/`, { params, ...customConfig }).then((res) => res.data);
  }

  static async getCategoryById(id: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/category/${id}/`, { ...customConfig }).then((res) => res.data);
  }

  static async editCategory(id: string | number, body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.put(`/api/v1/category/${id}/`, body, { ...customConfig });
  }

  static async deleteCategory(id: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.delete(`/api/v1/category/${id}/`, { ...customConfig });
  }

  static async createCategory(body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.post(`/api/v1/category/`, body, { ...customConfig });
  }

  static async getSubCategories(params?: CategoryQueryParams, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/subcategory/`, { params, ...customConfig }).then((res) => res.data);
  }

  static async getCSubategoryById(id: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/subcategory/${id}/`, { ...customConfig }).then((res) => res.data);
  }

  static async editSubCategory(id: string | number, body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.put(`/api/v1/subcategory/${id}/`, body, { ...customConfig });
  }

  static async deleteSubCategory(id: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.delete(`/api/v1/subcategory/${id}/`, { ...customConfig });
  }

  static async createSubCategory(body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.post(`/api/v1/subcategory/`, body, { ...customConfig });
  }
}
