import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  textError: string;
};

export const Error = ({ textError }: Props) => {
  return (
    <>
      <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
      &nbsp;&nbsp;&nbsp;
      <Text type="danger">{textError}</Text>
    </>
  );
};
