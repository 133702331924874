import { FC } from 'react';
import { Route } from 'react-router-dom';

import { ProtectedLayout } from '../components/protectedLayout/protectedLayout';
import { Profile } from '../models';
import { CountriesPage } from '../pages/countries/CountriesPage';
import { LocalCompanyPage } from '../pages/local-company/LocalCompanyPage';
import { PermissionsPage } from '../pages/permissions/PermissionsPage';
import { CreateUser } from '../pages/permissions/CreateUser/CreateUser';
import { CreateRole } from '../pages/permissions/CreateRole/CreateRole';
import { CategoriesPage } from '../pages/categories/CategoriesPage';
import { BrandsPage } from '../pages/brands/BrandsPage';
import { ProductsPage } from '../pages/products/ProductsPage';

type AppRouterProps = {
  profile: Profile;
  logout?: () => void;
};

export const AppRouter: FC<AppRouterProps> = ({ profile, logout }) => {
  return (
    <ProtectedLayout user={profile} logout={logout}>
      <Route exact path="/countries" component={CountriesPage} />
      <Route exact path="/local-company" component={LocalCompanyPage} />
      <Route exact path="/brands" component={BrandsPage} />
      <Route exact path="/categories" component={CategoriesPage} />
      <Route exact path="/products" component={ProductsPage} />

      <Route exact path="/permissions/:type" component={PermissionsPage} />
      <Route exact path="/permissions/users/create" component={CreateUser} />
      <Route path="/permissions/users/:userId/edit" component={CreateUser} />
      <Route exact path="/permissions/roles/create" component={CreateRole} />
      <Route path="/permissions/roles/:roleId/edit" component={CreateRole} />
    </ProtectedLayout>
  );
};
