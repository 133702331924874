import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Button, Drawer, Dropdown, Menu, PaginationProps, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import qs from 'qs';

import ProductsService from '../../services/products';
import { ProductsUploadDialog } from './components/ProductsUploadDialog';
import { SearchFilters } from './components/SearchFilters';
import { ConfirmationModal } from '../../components/confirmationModal/ConfirmationModal';
import { SidePanel } from './components/SidePanel';
import { CreateProductDialog } from './components/CreateProductDialog';
import { useProductsColumns } from './components/useProductsColumns';
import styles from './styles.module.scss';

const ExportMenuItems = (onOpen: (type: 'manual' | 'upload') => void) => (
  <Menu>
    <Menu.Item key="manual">
      <div onClick={() => onOpen('manual')}>Manual</div>
    </Menu.Item>
    <Menu.Item key="upload">
      <div onClick={() => onOpen('upload')}>Upload file</div>
    </Menu.Item>
  </Menu>
);

export const ProductsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [products, setProducts] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isManualOpen, setIsManualOpen] = useState(false);
  const [error, setError] = useState<string>(null);
  const [total, setTotal] = useState();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching } = useQuery(['product-table', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return ProductsService.getProducts({ page, limit, ...params }, { signal });
  });

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
    setDrawerTitle('');
    setSelectedProduct(null);
  };

  const onCloseManualDialog = () => {
    setIsManualOpen(false);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setSelectedProduct(null);
  };

  const columnsParams = useMemo(
    () => ({ setDrawerTitle, setSelectedProduct, showDrawer, setConfirmationModal }),
    [setDrawerTitle, setSelectedProduct, showDrawer, setConfirmationModal]
  );

  const productsColumns = useProductsColumns(columnsParams);

  const handleDownloadFile = (type: 'manual' | 'upload') => {
    if (type === 'manual') {
      setIsManualOpen(true);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (data) {
      setProducts(data?.results);
      setTotal(data?.count);
    }
  }, [data]);

  const getData = useCallback(() => {
    queryClient.refetchQueries('product-table');
  }, [queryClient]);

  const handleDelete = (barcode: string | number) => {
    ProductsService.deleteProduct(barcode)
      .then((res) => {
        getData();
        handleCloseModal();
      })
      .catch((err) => setError('Something went wrong'));
  };

  return (
    <div className={styles.layout_content}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <SearchFilters />
        <Dropdown overlay={ExportMenuItems(handleDownloadFile)}>
          <Button type="default" className={styles.export_btn} icon={<PlusOutlined />}>
            Product
          </Button>
        </Dropdown>
      </div>
      <Table
        columns={productsColumns}
        dataSource={products}
        loading={isFetching}
        bordered
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
      />
      <Drawer title={drawerTitle} placement="right" closable={true} onClose={onClose} open={openDrawer}>
        <SidePanel selectedProduct={selectedProduct} />
      </Drawer>
      <ProductsUploadDialog open={open} handleCancel={onCloseModal} fetchData={getData} />
      <CreateProductDialog open={isManualOpen} close={onCloseManualDialog} fetchData={getData} />
      <ConfirmationModal
        open={openConfirmationModal}
        close={handleCloseModal}
        title={``}
        textError={error}
        description={`Are you sure you want to delete ${selectedProduct?.barcode} ?`}
        onSubmit={() => handleDelete(selectedProduct?.barcode)}
      />
    </div>
  );
};
