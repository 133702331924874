import { Form, Input, Modal, TreeSelect } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { SelectItem } from '../../../components/form';
import ProductsService from '../../../services/products';
import FilterService from '../../../services/filter';
import BrandsService from '../../../services/brands';
import { fetchBrand } from '../../../utils/fetchSearchValueBrand';
import CategoriesService from '../../../services/categories';
import LocalCompanyService from '../../../services/local-company';
import { fetchLocalCompany } from '../../../utils/fetchSearchValue';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  selectedProduct?: any;
  fetchData?: () => void;
  close?: () => void;
};

export const CreateProductDialog = ({ open, selectedProduct, fetchData, close }: Props) => {
  const [form] = Form.useForm();
  const [subcategory, setSubcategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [additionalItems, setAdditinalItems] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('1');

  useEffect(() => {
    if (selectedProduct && open) {
      ProductsService.getProductByBarcode(selectedProduct?.barcode).then((res) => {
        form.setFieldsValue({
          ...res,
          brand_id: res?.brand?.id,
          category_id: res?.category?.id,
          subcategory_id: res?.subcategory?.id,
          size_of_package: res?.size_of_package?.id,
        });
      });
    } else {
      form.resetFields();
    }
    return () => {
      form.resetFields();
    };
  }, [selectedProduct, open]);

  const getCategories = useCallback(() => {
    FilterService.getNewOptions('category').then((res: any) => {
      const categoriesArray = res?.data?.map((item) => ({ label: item['name_val'], value: item['id'] }));
      setCategories(categoriesArray);
    });
  }, []);

  const getSubCategories = useCallback(() => {
    CategoriesService.getSubCategories({ limit: 50, category: selectedCategory || '' }).then((res: any) => {
      const subCategoriesArray = res?.results?.map((item) => ({ label: item['name_val'], value: item['id'] }));
      setSubcategory(subCategoriesArray);
    });
  }, [selectedCategory]);

  const getLocalCompanies = useCallback(() => {
    LocalCompanyService.getLocalCompanies({ limit: 20 }).then((res: any) => {
      const companiesArray = res?.results?.map((item) => ({ label: item['name_val'], value: item['id'] }));
      setCompanies(companiesArray);
    });
  }, []);

  const handleSearchLocalCompany = (value) => {
    fetchLocalCompany(value, setCompanies);
  };

  const getBrands = useCallback(() => {
    BrandsService.getBrands({ limit: 20 }).then((res: any) => {
      const subCategoriesArray = res?.results?.map((item) => ({ label: item['name_val'], value: item['id'] }));
      setBrands(subCategoriesArray);
    });
  }, []);

  const fetchAdditionalFilters = useCallback(() => {
    const arr: any = [];
    if ((selectedProduct?.category?.id || selectedCategory, selectedSubcategory)) {
      setIsShow(true);
      FilterService.getAvailableFilters(selectedProduct?.category?.id || selectedCategory, selectedSubcategory)
        .then((res) => {
          const promises = res?.data?.results.map(async (item: any) => {
            return await FilterService.getNewOptions(
              item?.filter_url,
              undefined,
              item?.filter_url === 'type_of_package' ? 'priority, type_of_packages' : undefined
            ).then((response) => {
              const newItem = {
                name: item.name_val,
                label: item?.label,
                type: item?.type,
                options:
                  item?.type === 'select'
                    ? response?.data?.map((obj: any) => ({
                        label: obj[item.name_val],
                        value: obj['id'] ? obj['id'] : obj[item.name_val],
                      }))
                    : response?.data?.results,
              };
              arr.push(newItem);
              return newItem;
            });
          });
          return Promise.all(promises);
        })
        .then((newItems) => {
          setAdditinalItems(newItems);
        })
        .catch((err) => console.error(err));
    } else {
      setIsShow(false);
    }
  }, [selectedSubcategory, selectedProduct, selectedCategory]);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    ProductsService.createProduct(body)
      .then((res) => {
        fetchData();
        close();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (barcode: string | number, body: any) => {
    ProductsService.editProduct(barcode, body)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.name_val?.[0]);
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleSearchBrand = (value) => {
    fetchBrand(value, setBrands);
  };

  const onFinish = (values: Record<string, string>) => {
    if (selectedProduct?.barcode) {
      handleEdit(selectedProduct?.barcode, values);
    } else {
      handleCreate(values);
    }
  };

  useEffect(() => {
    fetchAdditionalFilters();
    getSubCategories();
    getCategories();
    getLocalCompanies();
    getBrands();
    return () => {
      setSubcategory([]);
      setCategories([]);
      setCompanies([]);
      setBrands([]);
    };
  }, [getSubCategories, getBrands, fetchAdditionalFilters, getCategories, getLocalCompanies]);

  return (
    <Modal title="Create product" open={open} onOk={form.submit} onCancel={close} confirmLoading={confirmLoading}>
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={selectedProduct || null}>
        <Form.Item name="category_id" label="Category" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <SelectItem
            name="category_id"
            disabledMode
            options={categories}
            placeholder="Choose a category"
            loading={loading}
            maxTagCount="responsive"
            // onDropdownVisibleChange={handleDropdownVisibleChange}
            // onDeselect={handleDeselectSubcategoryValue}
            allowClear={true}
            onChange={(value) => setSelectedCategory(value)}
          />
        </Form.Item>
        <Form.Item name="subcategory_id" label="Subcategory" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <SelectItem
            name="subcategory_id"
            disabledMode
            options={subcategory}
            placeholder="Choose a subcategory"
            loading={loading}
            maxTagCount="responsive"
            // onDropdownVisibleChange={handleDropdownVisibleChange}
            // onDeselect={handleDeselectSubcategoryValue}
            allowClear={true}
            onChange={(value) => setSelectedSubcategory(value)}
          />
        </Form.Item>
        {isShow && (
          <>
            <Form.Item name="product" label="Product" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
              <Input />
            </Form.Item>
            <Form.Item name="barcode" label="Barcode" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
              <Input disabled={selectedProduct} />
            </Form.Item>
            <Form.Item
              name="local_company_id"
              label="Local company"
              rules={[{ required: true }]}
              style={{ marginTop: '16px' }}
            >
              <SelectItem
                name="local_company_id"
                options={companies}
                disabledMode
                placeholder="Choose a company"
                maxTagCount="responsive"
                onSearch={handleSearchLocalCompany}
                onClear={getLocalCompanies}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item name="brand_id" label="Brand" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
              <SelectItem
                name="brand_id"
                disabledMode
                options={brands}
                placeholder="Choose a brand"
                loading={loading}
                maxTagCount="responsive"
                onSearch={handleSearchBrand}
                onClear={getBrands}
                allowClear={true}
              />
            </Form.Item>
            {additionalItems &&
              additionalItems?.map((item: any) => {
                switch (item?.type) {
                  case 'tree':
                    return (
                      <Form.Item>
                        <label>{item?.label}</label>
                        <Form.Item name={item.name} noStyle>
                          <TreeSelect
                            treeData={item.options}
                            treeCheckable={false}
                            placeholder="Select the items"
                            maxTagCount="responsive"
                            allowClear
                          />
                        </Form.Item>
                      </Form.Item>
                    );
                  case 'select':
                    return (
                      <Form.Item name={item.name}>
                        <label>{item?.label}</label>
                        <SelectItem
                          name={item?.name}
                          disabledMode
                          options={item?.options}
                          placeholder="Select the items"
                          loading={loading}
                          maxTagCount="responsive"
                          allowClear={true}
                        />
                      </Form.Item>
                    );
                  default:
                    return '';
                }
              })}
          </>
        )}
      </Form>
      {error && <Error textError={error} />}
    </Modal>
  );
};
