import { Form, Switch } from 'antd';

type Props = {
  isModal: boolean;
};

export const SalesFormItems = ({ isModal }: Props) => {
  return (
    <>
      Sales statistics:
      <Form.Item
        valuePropName="checked"
        name={['sales_statistics_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['sales_statistics_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['sales_statistics_barcode']}
        label="- View SKU reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      Trends:
      <Form.Item
        valuePropName="checked"
        name={['trends_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['trends_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['trends_barcode']}
        label="- View barcode reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      Comparison:
      <Form.Item
        valuePropName="checked"
        name={['comparison_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['comparison_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['comparison_barcode']}
        label="- View barcode reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
    </>
  );
};
