import { useEffect, useState } from 'react';

import ProductsService from '../../../services/products';
import { Descriptions } from 'antd';

type Props = {
  selectedProduct?: any;
};

export const SidePanel = ({ selectedProduct }: Props) => {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (selectedProduct) {
      ProductsService.getProductByBarcode(selectedProduct?.barcode).then((res) => {
        setProduct(res);
      });
    }
    return () => {
      setProduct(null);
    };
  }, [selectedProduct]);

  return (
    <Descriptions bordered column={{ xs: 1, sm: 1, md: 1 }}>
      {product && (
        <>
          <Descriptions.Item key={1} label={'Product'}>
            {product?.product}
          </Descriptions.Item>
          <Descriptions.Item key={2} label={'Barcode'}>
            {product?.barcode}
          </Descriptions.Item>
          <Descriptions.Item key={3} label={'Category'}>
            {product?.category?.name_val}
          </Descriptions.Item>
          <Descriptions.Item key={4} label={'Subcategory'}>
            {product?.subcategory?.name_val}
          </Descriptions.Item>
          <Descriptions.Item key={5} label={'Local company'}>
            {product?.local_company?.name_val}
          </Descriptions.Item>
          <Descriptions.Item key={6} label={'Brand'}>
            {product?.brand?.name_val}
          </Descriptions.Item>
          {product?.battery_capacity?.[0]?.battery_capacity && (
            <Descriptions.Item key={7} label={'Battery capacity'}>
              {product?.battery_capacity?.[0]?.battery_capacity}
            </Descriptions.Item>
          )}
          {product?.concentration?.[0]?.concentration && (
            <Descriptions.Item key={8} label={'Concentration'}>
              {product?.concentration?.[0]?.concentration}
            </Descriptions.Item>
          )}
          {product?.flavor?.[0]?.flavor && (
            <Descriptions.Item key={9} label={'Flavor'}>
              {product?.flavor?.[0]?.flavor}
            </Descriptions.Item>
          )}
          {product?.liquid_volume?.[0]?.liquid_volume && (
            <Descriptions.Item key={10} label={'Liquid volume'}>
              {product?.liquid_volume?.[0]?.liquid_volume}
            </Descriptions.Item>
          )}
          {product?.puff?.[0]?.puff && (
            <Descriptions.Item key={11} label={'Puff'}>
              {product?.puff?.[0]?.puff}
            </Descriptions.Item>
          )}
          {product?.salt_nicotine?.[0]?.salt_nicotine && (
            <Descriptions.Item key={12} label={'Salt nicotine'}>
              {product?.salt_nicotine?.[0]?.salt_nicotine}
            </Descriptions.Item>
          )}
          {product?.type_of_package?.[0]?.type_of_package && (
            <Descriptions.Item key={12} label={'Type of package'}>
              {product?.type_of_package?.[0]?.type_of_package}
            </Descriptions.Item>
          )}
          {product?.size_of_package?.[0]?.size_of_package && (
            <Descriptions.Item key={13} label={'Size of package'}>
              {product?.size_of_package?.[0]?.size_of_package}
            </Descriptions.Item>
          )}
        </>
      )}
    </Descriptions>
  );
};
