import { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import CategoriesService from '../../../services/categories';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  handleCancel: () => void;
  formData?: Record<string, string>;
  fetchData?: () => void;
};

export const CategoriesDialog = ({ open, handleCancel, formData, fetchData }: Props) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
    return () => {
      form.resetFields();
      setError(null);
    };
  }, [formData]);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    CategoriesService.createCategory(body)
      .then((res) => {
        handleCancel();
        fetchData();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.name_val?.[0]);
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (id: string | number, body: any) => {
    CategoriesService.editCategory(id, body)
      .then((res) => {
        handleCancel();
        fetchData();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.name_val?.[0]);
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const onFinish = (values: Record<string, string>) => {
    if (formData?.id) {
      handleEdit(formData?.id, values);
    } else {
      handleCreate(values);
    }
  };

  return (
    <Modal
      forceRender
      title="Title"
      open={open}
      onOk={form.submit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form form={form} onFinish={onFinish} initialValues={formData || null}>
        <Form.Item name="name_val" label="Category" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        {error && <Error textError={error} />}
      </Form>
    </Modal>
  );
};
