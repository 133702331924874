import { AxiosRequestConfig } from 'axios';
import { PageQueryParams } from '../models';
import fetchClient from './axiosInstance';

interface ProductsQueryParams extends PageQueryParams {
  category?: string | number;
  all?: boolean;
  local_company?: string;
  international_company?: string;
  search?: string;
  subcategory?: string;
}

export default class ProductsService {
  static async getProducts(params?: ProductsQueryParams, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/products/`, { params, ...customConfig }).then((res) => res.data);
  }

  static async getProductByBarcode(barcode: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/products/${barcode}/`, { ...customConfig }).then((res) => res.data);
  }

  static async editProduct(barcode: string | number, body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.put(`/api/v1/products/${barcode}/`, body, { ...customConfig });
  }

  static async deleteProduct(barcode: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.delete(`/api/v1/products/${barcode}/`, { ...customConfig });
  }

  static async createProduct(body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.post(`/api/v1/products/`, body, { ...customConfig });
  }

  static async downloadExample(subcategoryId: string) {
    return fetchClient
      .get(`/api/v1/products/xlsx/${subcategoryId}`, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'blob' },
      })
      .then((res) => res.data);
  }

  static async uploadProduct(body: any) {
    return fetchClient.post(`/api/v1/products/xlsx/`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}
