import { AxiosRequestConfig } from 'axios';
import { PageQueryParams } from '../models';
import fetchClient from './axiosInstance';

interface BrandsQueryParams extends PageQueryParams {
  category?: string | number;
  all?: boolean;
  local_company?: string;
  international_company?: string;
  search?: string;
  subcategory?: string;
}

export default class BrandsService {
  static async getBrands(params?: BrandsQueryParams, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/brand/`, { params, ...customConfig }).then((res) => res.data);
  }

  static async getBrandById(id: string | number, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/brand/${id}/?category=${category}`, { ...customConfig }).then((res) => res.data);
  }

  static async editBrand(id: string | number, body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.put(`/api/v1/brand/${id}/`, body, { ...customConfig });
  }

  static async deleteBrand(id: string | number, customConfig?: AxiosRequestConfig) {
    return fetchClient.delete(`/api/v1/brand/${id}/`, { ...customConfig });
  }

  static async createBrand(body: any, customConfig?: AxiosRequestConfig) {
    return fetchClient.post(`/api/v1/brand/`, body, { ...customConfig });
  }
}
