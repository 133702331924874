import { ColumnsType } from 'antd/lib/table';
import { Button, Space } from 'antd';

interface DataType {
  key: React.Key;
  name: string;
}

export const useCountriesColumns = ({
  setCountry,
  showModal,
  setSelectedCountry,
  setConfirmationModal,
}: any): ColumnsType<DataType> => {
  return [
    {
      title: 'Country',
      dataIndex: 'name_val',
      key: 'name_val',
    },
    {
      title: 'Country code',
      dataIndex: 'country_code',
      key: 'country_code',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setCountry(record);
              showModal();
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setSelectedCountry(record);
              setConfirmationModal(true);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
};
