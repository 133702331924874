import { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';

import CountriesService from '../../../services/countries';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  handleCancel: () => void;
  countryData?: Record<string, string>;
  fetchData?: () => void;
};

export const CountriesDialog = ({ open, handleCancel, countryData, fetchData }: Props) => {
  const [form] = Form.useForm();
  const [error, setError] = useState<string>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (countryData && open) {
      form.setFieldsValue(countryData);
    }
    return () => {
      form.resetFields();
    };
  }, [countryData, open]);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    CountriesService.createCountry(body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.country_code?.[0]);
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (id: string | number, body: any) => {
    CountriesService.editCountry(id, body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.country_code?.[0]);
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const onFinish = (values: Record<string, string>) => {
    if (countryData?.id) {
      handleEdit(countryData?.id, values);
    } else {
      handleCreate(values);
    }
  };

  return (
    <Modal
      forceRender
      title="Title"
      open={open}
      onOk={form.submit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form form={form} onFinish={onFinish} initialValues={countryData || null}>
        <Form.Item name="name_val" label="Country name" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        <Form.Item name="country_code" label="Country code" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
      </Form>
      {error && <Error textError={error} />}
    </Modal>
  );
};
