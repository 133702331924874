import { useCallback, useEffect, useState } from 'react';
import { Button, Collapse, Form, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';

import { Subcategory } from '../../../models';
import CategoriesService from '../../../services/categories';
import { SelectItem } from '../../../components/form/SelectItem';
import ProductsService from '../../../services/products';
import { downloadFile } from '../../../utils/downloadFile';

const { Panel } = Collapse;

type Props = {
  open: boolean;
  handleCancel: () => void;
  fetchData?: () => void;
};

export const ProductsUploadDialog = ({ open, handleCancel, fetchData }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [subcategory, setSubcategory] = useState<Subcategory[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (open) {
      form.setFieldsValue(null);
    }
    return () => {
      form.resetFields();
    };
  }, [open]);

  const fetchDefaultFilters = useCallback(async () => {
    setLoading(true);
    try {
      const [subcategoryRes] = await Promise.allSettled([CategoriesService.getSubCategories({ limit: 50 })]);

      const subcategories =
        subcategoryRes.status === 'fulfilled'
          ? subcategoryRes.value.results.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
          : [];

      setSubcategory(subcategories);
    } catch (error) {
      setSubcategory([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchDefaultFilters();
    }
    return () => {
      setSubcategory([]);
    };
  }, [fetchDefaultFilters, open]);

  const handleCreate = async (body: any) => {
    setConfirmLoading(true);
    const file = selectedFile || body?.file?.fileList[0]?.originFileObj;
    const formData = new FormData();
    if (file instanceof File) {
      formData.append('file', file);
      formData.append('subcategory', body?.subcategory_ids);

      ProductsService.uploadProduct(formData)
        .then((res) => {
          if (res?.data === 'ok') {
            fetchData();
            handleCancel();
          } else {
            setError(res?.data);
          }
        })
        .catch((err) => setError(err?.response?.data))
        .finally(() => setConfirmLoading(false));
    }
  };

  const onFinish = (values: Record<string, string>) => {
    handleCreate(values);
  };

  const handleDownloadExample = () => {
    ProductsService.downloadExample(form.getFieldValue('subcategory_ids')).then((res) => {
      downloadFile(res, `example_${form.getFieldValue('subcategory_ids')}`);
    });
  };

  return (
    <Modal title="Create brand" open={open} onOk={form.submit} onCancel={handleCancel} confirmLoading={confirmLoading}>
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={null}>
        <Form.Item name="subcategory_ids" label="Subcategory" style={{ marginTop: '16px' }}>
          <SelectItem
            name="subcategory_ids"
            disabledMode
            options={subcategory}
            placeholder="Choose a subcategory"
            loading={loading}
            maxTagCount="responsive"
            allowClear={true}
          />
        </Form.Item>
        <Form.Item name="file" style={{ marginTop: '16px' }}>
          <Upload
            id="file"
            name="file"
            maxCount={1}
            accept=".xls, .xlsx"
            onChange={(fileInfo: any) => {
              const { name, originFileObj = {}, status } = fileInfo.file;
              const { webkitRelativePath = '' } = originFileObj;
              const pathArr = webkitRelativePath.split('/');
              pathArr.shift();
              const dropFatherPath = pathArr.join('/');
            }}
            beforeUpload={(file: any) => {
              setSelectedFile(file);

              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form>

      {error && (
        <>
          <Collapse>
            <Panel header="You have some errors, please check below:" key="1">
              <ReactJson src={error} style={{ fontSize: 10 }} />
            </Panel>
          </Collapse>
        </>
      )}
      <Button
        type="link"
        disabled={form.getFieldValue('subcategory_ids') === 'undefined'}
        onClick={handleDownloadExample}
      >
        Example
      </Button>
    </Modal>
  );
};
