import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Modal, TreeSelect } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import LocalCompanyService from '../../../services/local-company';
import { Region, Subcategory } from '../../../models';
import FilterService from '../../../services/filter';
import { SelectItem } from '../../../components/form';
import CountriesService from '../../../services/countries';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  handleCancel: () => void;
  companyData?: Record<string, any>;
  fetchData?: () => void;
};

export const LocalCompanyDialog = ({ open, handleCancel, companyData, fetchData }: Props) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [regions, setRegions] = useState<Region[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [subcategory, setSubcategory] = useState<Subcategory[]>([]);
  const [error, setError] = useState<string>(null);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    if (companyData && open) {
      form.setFieldsValue({
        ...companyData,
        category_ids: companyData?.categories?.map((item: any) => item.id),
        subcategory_ids: companyData?.subcategories?.map((item: any) => item.id),
        country_id: companyData?.country?.id,
        city_ids: companyData?.cities?.map((item: any) => item.id),
      });
    }
    return () => {
      form.resetFields();
    };
  }, [companyData, open]);

  const fetchDefaultFilters = useCallback(async () => {
    setLoading(true);
    try {
      const [citiesRes, subcategoryRes, categoryRes, countryRes] = await Promise.allSettled([
        FilterService.getCities(),
        FilterService.getNewOptions('subcategory'),
        FilterService.getNewOptions('category'),
        CountriesService.getCountries(),
      ]);

      const cities = citiesRes.status === 'fulfilled' ? citiesRes?.value?.data?.results : [];
      const subcategories =
        subcategoryRes.status === 'fulfilled'
          ? subcategoryRes.value.data.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
          : [];
      const categories =
        categoryRes.status === 'fulfilled'
          ? categoryRes.value.data.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
          : [];
      const countries =
        countryRes.status === 'fulfilled'
          ? countryRes.value?.results?.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
          : [];

      setRegions(cities);
      setSubcategory(subcategories);
      setCategory(categories);
      setCountry(countries);
    } catch (error) {
      setRegions([]);
      setSubcategory([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchDefaultFilters();
    }
    return () => {
      setSubcategory([]);
      setRegions([]);
    };
  }, [fetchDefaultFilters, open]);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    LocalCompanyService.createLocalCompany(body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (id: string | number, body: any) => {
    LocalCompanyService.editLocalCompany(id, body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const onFinish = (values: Record<string, string>) => {
    if (companyData?.id) {
      handleEdit(companyData?.id, values);
    } else {
      handleCreate(values);
    }
  };

  return (
    <Modal
      title="Create company"
      open={open}
      onOk={form.submit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={companyData || null}>
        <Form.Item name="name_val" label="Company name" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        <Form.Item name="category_ids" label="Category" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <SelectItem
            name="category_ids"
            mode="multiple"
            options={category}
            placeholder="Choose a category"
            loading={loading}
            maxTagCount="responsive"
            // onDropdownVisibleChange={handleDropdownVisibleChange}
            // onDeselect={handleDeselectSubcategoryValue}
            allowClear={true}
            // onChange={(value) => value}
          />
        </Form.Item>
        <Form.Item
          name="subcategory_ids"
          rules={[{ required: true }]}
          label="Subcategory"
          style={{ marginTop: '16px' }}
        >
          <SelectItem
            name="subcategory_ids"
            mode="multiple"
            options={subcategory}
            placeholder="Choose a subcategory"
            loading={loading}
            maxTagCount="responsive"
            // onDropdownVisibleChange={handleDropdownVisibleChange}
            // onDeselect={handleDeselectSubcategoryValue}
            allowClear={true}
            // onChange={(value) => value}
          />
        </Form.Item>
        <Form.Item name="country_id" rules={[{ required: true }]} label="Country" style={{ marginTop: '16px' }}>
          <SelectItem
            name="country_id"
            options={country}
            disabledMode
            placeholder="Choose a country"
            loading={loading}
            maxTagCount="responsive"
            // onDropdownVisibleChange={handleDropdownVisibleChange}
            // onDeselect={handleDeselectSubcategoryValue}
            allowClear={true}
            // onChange={(value) => value}
          />
        </Form.Item>
        <Form.Item name="city_ids" label="City" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <TreeSelect
            treeCheckable={true}
            labelInValue={false}
            loading={loading}
            placeholder="Please select"
            maxTagCount="responsive"
            allowClear
            treeDefaultExpandedKeys={['all']}
            filterTreeNode
            treeNodeFilterProp="title"
            treeData={[
              {
                title: <span>Select all</span>,
                value: 'all',
                key: 'all',
                children: regions,
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="is_active" label="Is active" valuePropName="checked" style={{ marginTop: '16px' }}>
          <Checkbox />
        </Form.Item>
      </Form>
      {error && <Error textError={error} />}
    </Modal>
  );
};
