import { Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

export const UserMenuItems = (logout?: () => void, openFilter?: () => void) => (
  <Menu>
    <Menu.Item key="password">
      <div onClick={openFilter}>Change password</div>
    </Menu.Item>
    <Menu.Item key="logout">
      <div onClick={logout}>
        <LogoutOutlined /> Logout
      </div>
    </Menu.Item>
  </Menu>
);
