import { useCallback, useEffect, useState } from 'react';
import { Button, Input, List, Popconfirm, Typography, message } from 'antd';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import CategoriesService from '../../../services/categories';
import { Error } from '../../../components/error/Error';

type Props = {
  categoryId: string;
  fetchData?: () => void;
};

export const SidePanel = ({ categoryId }: Props) => {
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [editSubCategoryName, setEditSubCategoryName] = useState<string>('');
  const [newSubcategoryName, setNewSubcategoryName] = useState<string>('');
  const [error, setError] = useState<string>(null);
  const [popConfirmVisible, setPopConfirmVisible] = useState<string | null>(null);

  const fetchSubCategories = useCallback(() => {
    setLoading(true);
    CategoriesService.getSubCategories({ limit: 100, category: categoryId })
      .then((res) => {
        setSubCategories(res?.results);
      })
      .finally(() => setLoading(false));
  }, [categoryId]);

  useEffect(() => {
    fetchSubCategories();
  }, [fetchSubCategories]);

  const handleCreate = () => {
    setConfirmLoading(true);
    CategoriesService.createSubCategory({ name_val: newSubcategoryName, parent_id: categoryId })
      .then((res) => {
        setNewSubcategoryName('');
        setIsAddMode(false);
        fetchSubCategories();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          message.error(err?.response?.data?.name_val?.[0]);
        } else {
          message.error('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (id: string | number) => {
    setConfirmLoading(true);
    CategoriesService.editSubCategory(id, { name_val: editSubCategoryName, parent_id: categoryId })
      .then((res) => {
        setEditItemId(null);
        setEditSubCategoryName('');
        fetchSubCategories();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          message.error(err?.response?.data?.name_val?.[0]);
        } else {
          message.error('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEditClick = (itemId: string) => {
    setEditItemId(itemId);
  };

  const handleCancelEdit = () => {
    setEditItemId(null);
  };

  const handleAddClick = () => {
    setIsAddMode(true);
    setError(null);
  };

  const handleCancelAdd = () => {
    setIsAddMode(false);
    setNewSubcategoryName('');
    setError(null);
  };

  const showPopconfirm = (itemId: string) => {
    setPopConfirmVisible(itemId);
  };

  const handleDelete = (id: string | number) => {
    setConfirmLoading(true);
    CategoriesService.deleteSubCategory(id)
      .then((res) => {
        fetchSubCategories();
        setPopConfirmVisible(null);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          message.error(err?.response?.data?.name_val?.[0]);
        } else {
          message.error('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleCancel = () => {
    setPopConfirmVisible(null);
  };

  return (
    <>
      <List
        loading={loading}
        dataSource={subCategories}
        renderItem={(item) => (
          <List.Item key={item?.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {editItemId === item?.id ? (
              <>
                <Input
                  placeholder="subcategory"
                  defaultValue={item?.name_val}
                  onChange={(e) => setEditSubCategoryName(e.target.value)}
                  style={{ marginRight: 10 }}
                />
                <Button
                  type="text"
                  icon={<CheckOutlined />}
                  onClick={() => handleEdit(item?.id)}
                  style={{ marginRight: 10 }}
                  loading={confirmLoading}
                />
                <Button type="text" icon={<CloseOutlined />} onClick={handleCancelEdit} />
              </>
            ) : (
              <>
                <Typography.Text>{item?.name_val}</Typography.Text>
                <div>
                  <Button
                    type="default"
                    icon={<EditOutlined />}
                    onClick={() => handleEditClick(item?.id)}
                    style={{ marginRight: 10 }}
                  />
                  <Popconfirm
                    title={'Are you sure to delete this subcategory?'}
                    open={popConfirmVisible === item?.id}
                    onConfirm={() => handleDelete(item?.id)}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                  >
                    <Button type="default" icon={<DeleteOutlined />} onClick={() => showPopconfirm(item?.id)} />
                  </Popconfirm>
                </div>
              </>
            )}
          </List.Item>
        )}
      />

      {isAddMode ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              placeholder="New subcategory"
              value={newSubcategoryName}
              onChange={(e) => setNewSubcategoryName(e.target.value)}
              style={{ marginRight: 10 }}
            />
            <Button
              type="text"
              icon={<CheckOutlined />}
              onClick={handleCreate}
              loading={confirmLoading}
              style={{ marginRight: 10 }}
            />
            <Button type="text" icon={<CloseOutlined />} onClick={handleCancelAdd} />
          </div>
          {error && <Error textError={error} />}
        </>
      ) : (
        <Button type="default" icon={<PlusOutlined />} onClick={handleAddClick}>
          subcategory
        </Button>
      )}
    </>
  );
};
