import { Form, Select, Spin } from 'antd';

type Props = {
  name: string;
  options: any[];
  onChange?: (value?: any) => void;
  onSearch?: (value: any) => void;
  onDropdownVisibleChange?: (isOpen: boolean) => void;
  onDeselect?: (value: any) => void;
  rules?: any[];
  loading?: boolean;
  placeholder?: string;
  disabledMode?: boolean;
  disabled?: boolean;
  mode?: 'multiple' | 'tags';
  allowClear?: boolean;
  maxTagCount?: number | 'responsive';
  filterOption?: boolean;
  onClear?: () => void;
  style?: any;
};

export const SelectItem = ({
  name,
  options,
  onChange,
  onSearch,
  onDropdownVisibleChange,
  onDeselect,
  rules,
  loading,
  placeholder,
  disabledMode,
  disabled = false,
  mode = 'multiple',
  allowClear = true,
  maxTagCount = 'responsive',
  filterOption = true,
  onClear,
  style,
}: Props) => {
  return (
    <Form.Item name={name} noStyle rules={rules}>
      <Select
        mode={disabledMode ? undefined : mode}
        options={options || []}
        onChange={onChange}
        onSearch={onSearch}
        onDropdownVisibleChange={!disabledMode ? onDropdownVisibleChange : undefined}
        onDeselect={onDeselect}
        showSearch
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder}
        notFoundContent={loading ? <Spin size="small" /> : null}
        maxTagCount={maxTagCount}
        onClear={onClear}
        filterOption={
          filterOption === false
            ? false
            : (input, option: any) => {
                return (
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                );
              }
        }
        style={style}
      />
    </Form.Item>
  );
};
