import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'antd';

import CountriesService from '../../services/countries';
import { CountriesDialog } from './components/Dialog';
import { ConfirmationModal } from '../../components/confirmationModal/ConfirmationModal';
import styles from './styles.module.scss';
import { useCountriesColumns } from './components/useCountriesColumns';

export const CountriesPage = () => {
  const [countries, setCountries] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [country, setCountry] = useState<Record<string, string>>(null);

  const showModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setSelectedCountry(null);
  };

  const columnsParams = useMemo(
    () => ({ setCountry, showModal, setSelectedCountry, setConfirmationModal }),
    [setCountry, showModal, setSelectedCountry, setConfirmationModal]
  );

  const countriesColumns = useCountriesColumns(columnsParams);

  useEffect(() => {
    fetchCountries();
    return () => {
      setError(null);
    };
  }, []);

  const fetchCountries = () => {
    setLoading(true);
    CountriesService.getCountries()
      .then((res) => {
        const countriesWithKeys = res?.results.map((country) => ({ ...country, key: country.id }));
        setCountries(countriesWithKeys);
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (id: string | number) => {
    CountriesService.deleteCountry(id)
      .then((res) => {
        fetchCountries();
        handleCloseModal();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.country_code?.[0]);
        } else {
          setError('Something went wrong');
        }
      });
  };

  return (
    <div className={styles.layout_content}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Button
          type="default"
          onClick={() => {
            showModal();
            setCountry(null);
          }}
        >
          + Country
        </Button>
      </div>
      <Table columns={countriesColumns} dataSource={countries} loading={loading} bordered />
      <CountriesDialog open={open} countryData={country} handleCancel={handleCancel} fetchData={fetchCountries} />
      <ConfirmationModal
        open={openConfirmationModal}
        close={handleCloseModal}
        title={``}
        textError={error}
        description={`Are you sure you want to delete ${selectedCountry?.name_val} ?`}
        onSubmit={() => handleDelete(selectedCountry?.id)}
      />
    </div>
  );
};
