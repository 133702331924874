import { AxiosRequestConfig } from 'axios';
import { PageQueryParams } from '../models';
import fetchClient from './axiosInstance';

interface CountriesQueryParams extends PageQueryParams {
  category?: string | number;
  all?: boolean;
}

export default class CountriesService {
  static async getCountries(params?: CountriesQueryParams, customConfig?: AxiosRequestConfig) {
    return fetchClient.get(`/api/v1/geo/countries/`, { params, ...customConfig }).then((res) => res.data);
  }

  static async getCountry(id: string | number, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient
      .get(`/api/v1/geo/countries/${id}/?category=${category}`, { ...customConfig })
      .then((res) => res.data);
  }

  static async editCountry(id: string | number, body: any, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.put(`/api/v1/geo/countries/${id}/`, body, { ...customConfig });
  }

  static async deleteCountry(id: string | number, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.delete(`/api/v1/geo/countries/${id}/`, { ...customConfig });
  }

  static async createCountry(body: any, category?: string, customConfig?: AxiosRequestConfig) {
    return fetchClient.post(`/api/v1/geo/countries/`, body, { ...customConfig });
  }
}
