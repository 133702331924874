import BrandsService from '../services/brands';

let timeout: ReturnType<typeof setTimeout> | null;

export const fetchBrand = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  const fetchBrands = () => {
    BrandsService.getBrands({ search: value }).then((res) => {
      const data = res?.results?.map((item: any) => ({ label: item['name_val'], value: item['id'] }));
      callback(data);
    });
  };

  if (value) {
    timeout = setTimeout(fetchBrands, 500);
  }
};
