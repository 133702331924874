import { useHistory } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Button, Tag, Tooltip } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';

interface DataType {
  key: React.Key;
  name: string;
}

export const useUserColumns = ({
  canEdit,
  setIsUserActive,
  setId,
  handleOpenModal,
  setSelectedData,
  setOpenDeleteModal,
}: any): ColumnsType<DataType> => {
  const history = useHistory();
  return [
    {
      title: 'User',
      width: 150,
      render: (value: any) => value?.first_name + ' ' + value?.last_name,
    },
    {
      title: 'Username',
      width: 180,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Company',
      width: 150,
      render: (value: any) => value?.company?.name_val,
    },
    {
      title: 'Role',
      width: 100,
      render: (value: any) => value?.role?.name,
    },
    {
      title: 'Email',
      width: 180,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      width: 100,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'status',
      width: 80,
      render: (value: any) => (value?.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Not active</Tag>),
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: any) => (
        <>
          {canEdit && (
            <Tooltip placement="top" title="Edit">
              <Button type="text" onClick={() => history.push(`/permissions/users/${value.id}/edit`)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          )}

          {canEdit &&
            (value.is_active ? (
              <Tooltip placement="top" title="Block user">
                <Button
                  type="text"
                  onClick={() => {
                    setIsUserActive(false);
                    setId(value?.id);
                    handleOpenModal();
                  }}
                >
                  <StopOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Unblock user">
                <Button
                  type="text"
                  onClick={() => {
                    setIsUserActive(true);
                    setId(value?.id);
                    handleOpenModal();
                  }}
                >
                  <CheckCircleOutlined />
                </Button>
              </Tooltip>
            ))}

          {canEdit && (
            <Tooltip placement="top" title="Delete">
              <Button
                type="text"
                onClick={() => {
                  setSelectedData(value);
                  setOpenDeleteModal(true);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
};
