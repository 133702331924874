import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';

import { Subcategory } from '../../../models';
import CategoriesService from '../../../services/categories';
import { SelectItem } from '../../../components/form/SelectItem';
import BrandsService from '../../../services/brands';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  handleCancel: () => void;
  companyData?: Record<string, any>;
  fetchData?: () => void;
};

export const BrandsDialog = ({ open, handleCancel, companyData, fetchData }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<any[]>([]);
  const [error, setError] = useState<string>(null);
  const [subcategory, setSubcategory] = useState<Subcategory[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (companyData && open) {
      form.setFieldsValue({
        ...companyData,
        category_ids: companyData?.categories?.map((item: any) => item.id),
        subcategory_ids: companyData?.subcategories?.map((item: any) => item.id),
      });
    }
    return () => {
      form.resetFields();
    };
  }, [companyData, open]);

  const fetchDefaultFilters = useCallback(async () => {
    setLoading(true);
    try {
      const [categoryRes, subcategoryRes] = await Promise.allSettled([
        CategoriesService.getCategories({ limit: 50 }),
        CategoriesService.getSubCategories({ limit: 50 }),
      ]);
      const categories =
        categoryRes.status === 'fulfilled'
          ? categoryRes.value.results.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
          : [];

      const subcategories =
        subcategoryRes.status === 'fulfilled'
          ? subcategoryRes.value.results.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
          : [];

      setCategory(categories);
      setSubcategory(subcategories);
    } catch (error) {
      setSubcategory([]);
      setCategory([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchDefaultFilters();
    }
    return () => {
      setSubcategory([]);
      setCategory([]);
    };
  }, [fetchDefaultFilters, open]);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    BrandsService.createBrand({ name_val: body?.name_val, subcategory_ids: body?.subcategory_ids })
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (id: string | number, body: any) => {
    BrandsService.editBrand(id, body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const onFinish = (values: Record<string, string>) => {
    if (companyData?.id) {
      handleEdit(companyData?.id, values);
    } else {
      handleCreate(values);
    }
  };

  return (
    <Modal title="Create brand" open={open} onOk={form.submit} onCancel={handleCancel} confirmLoading={confirmLoading}>
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={companyData || null}>
        <Form.Item name="name_val" label="Brand" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
        <Form.Item
          name="subcategory_ids"
          label="Subcategory"
          rules={[{ required: true }]}
          style={{ marginTop: '16px' }}
        >
          <SelectItem
            name="subcategory_ids"
            options={subcategory}
            placeholder="Choose a subcategory"
            loading={loading}
            maxTagCount="responsive"
            allowClear={true}
          />
        </Form.Item>
      </Form>
      {error && <Error textError={error} />}
    </Modal>
  );
};
