import { ColumnsType } from 'antd/lib/table';
import { Button, Space, Tag } from 'antd';

interface DataType {
  key: React.Key;
  name: string;
}

export const useCompaniesColumns = ({
  setCompany,
  showModal,
  setSelectedCompany,
  setConfirmationModal,
}: any): ColumnsType<DataType> => {
  return [
    {
      title: 'Company',
      dataIndex: 'name_val',
      key: 'name_val',
    },
    {
      title: 'Categories',
      key: 'categories',
      render: (_, record: any) => (
        <Space size="middle">{record?.categories?.map((item) => item.name_val).join(',')}</Space>
      ),
    },
    {
      title: 'Subcategories',
      key: 'subcategories',
      render: (_, record: any) => (
        <Space size="middle">{record?.subcategories?.map((item) => item.name_val).join(',')}</Space>
      ),
    },
    {
      title: 'Country',
      key: 'country',
      render: (_, record: any) => <Space size="middle">{record?.country?.name_val}</Space>,
    },
    {
      title: 'Status',
      width: 80,
      render: (value: any) => (value?.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Not active</Tag>),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setCompany(record);
              showModal();
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setSelectedCompany(record);
              setConfirmationModal(true);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
};
