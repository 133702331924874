import { AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';
export default class FilterService {
  static async getAvailableFilters(category: string, subcategory?: string) {
    const params = { subcategory };
    return fetchClient.get(`/api/v1/category_characteristics/?category=${category}&limit=1000`, { params });
  }
  static async getOptions(type: string): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/${type}/`);
  }

  static getNewOptions(type: string, ids?: string, ordering?: string): Promise<AxiosResponse> {
    const params = {
      ids,
      ordering,
    };
    return fetchClient.get(`/api/v1/${type}/?all=true`, { params });
  }

  static async getCities(all?: boolean): Promise<AxiosResponse> {
    const params = {
      all,
    };
    return fetchClient.get(`/api/v1/geo/regions/filter/`, { params });
  }

  static async getExtrapotableCities(): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/city/extrapolatable/`);
  }

  static async getBrand(params: { local_company?: string; sub_category?: string }): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/brand/?all=true`, { params });
  }

  static async getBarcode(params: {
    local_company?: string;
    sub_category?: string;
    brand?: string;
  }): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/barcode/?all=true`, { params });
  }

  static async getLocalCompany(params?: { sub_category: string; brand: string }): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/local_company/?all=true`, { params });
  }

  static async getLocalCompanyRelational(params: { sub_category: string; brand: string }): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/filters/local_company/relational/?all=true`, { params });
  }

  static async getLastTransaction(report_name?: string): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/last_transaction/?report_name=${report_name}`);
  }
}
