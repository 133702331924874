import { ColumnsType } from 'antd/lib/table';
import { Button, Space } from 'antd';

interface DataType {
  key: React.Key;
  name: string;
}

export const useProductsColumns = ({
  setDrawerTitle,
  setSelectedProduct,
  showDrawer,
  setConfirmationModal,
}: any): ColumnsType<DataType> => {
  return [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: 'Brand',
      key: 'brand',
      render: (_, record: any) => <Space size="middle">{record?.brand?.name_val}</Space>,
    },
    {
      title: 'Categories',
      key: 'category',
      render: (_, record: any) => <Space size="middle">{record?.category?.name_val}</Space>,
    },
    {
      title: 'Subcategory',
      key: 'subcategory',
      render: (_, record: any) => <Space size="middle">{record?.subcategory?.name_val}</Space>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setDrawerTitle(record?.product);
              setSelectedProduct(record);
              showDrawer();
            }}
          >
            View
          </Button>
          <Button
            onClick={() => {
              setSelectedProduct(record);
              setConfirmationModal(true);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
};
